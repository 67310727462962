import Reseller from './Reseller.vue';

export default Reseller;

export type ResellerData = {
    resellerMinClientAuthoringCredit: number;
    resellerMinClientAuthoringSub: number;
    resellerMinClientSnapshotPrice: number;
    resellerMinClientPremiumAmpCreditPrice: number;
    resellerMinClientRecurringPremiumAmpCreditPrice: number;
    resellerMinClientDefiCreditPrice: number;
    resellerMinClientRecurringDefiCreditPrice: number;
    resellerMinClientBlowPackPrice: number;
    resellerMinClientRecurringBlowPackPrice: number;
    isClientCabin: boolean;
    isAnnouncementEngine: boolean;
    artifactNamePure: string;
    artifactName: string;
};
