<template>
    <v-card
        class="mb-2"
        :class="{ 'px-5 py-4 mb-5': $vuetify.breakpoint.mdAndUp }"
    >
        <v-row class="ma-0">
            <v-col cols="12" lg="9" xl="6" class="pa-0">
                <a-form :submit="save">
                    <v-card-title class="mb-4">Whitelabel Options</v-card-title>

                    <v-card-text>
                        <v-row dense>
                            <v-col cols="12">
                                <a-text-input
                                    v-model="business_name"
                                    label="Business Name"
                                    rules="required"
                                    autocomplete="off"
                                />
                            </v-col>
                            <v-col cols="12">
                                <a-text-input
                                    v-model="product_name"
                                    label="Product Name"
                                    rules="required"
                                    hint="This will appear in various places throughout the portal. If you do not supply one, a generic name will be used."
                                    persistent-hint
                                    autocomplete="off"
                                />
                            </v-col>
                            <v-col cols="12" md="6">
                                <a-text-input
                                    v-model="subdomain"
                                    label="Subdomain"
                                    rules="required|alpha_dash|availability:/users/check_subdomain_availability"
                                    :debounce="500"
                                    :hint="subdomainHint"
                                    persistent-hint
                                />
                            </v-col>
                            <v-col
                                v-if="subdomain && unseenMachineLink"
                                cols="12"
                                md="6"
                                class="text-center"
                                :class="{ 'pt-7': $vuetify.breakpoint.mdAndUp }"
                            >
                                <v-btn
                                    :href="unseenMachineLink"
                                    color="primary"
                                    x-large
                                >
                                    Your Amp Sales Funnel
                                    <br />
                                    {{ unseenMachineLink }}
                                </v-btn>
                            </v-col>
                            <v-col cols="12">
                                <a-text-input
                                    v-model="reseller_booking_url"
                                    label="Book A Meeting Link (optional)"
                                    rules="url"
                                    placeholder="http(s)://..."
                                    hint="This will be the a link to book a meeting via a platform of your choice (Calendy, ScheduleOnce, etc)."
                                    persistent-hint
                                    autocomplete="off"
                                />
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-select
                                    v-model="reseller_currency"
                                    filled
                                    name="reseller_currency"
                                    :items="currencyOptions"
                                    item-text="label"
                                    item-value="value"
                                    label="Currency"
                                    color="secondary"
                                    hint="Your clients will see the prices and pay you in this currency."
                                    persistent-hint
                                />
                            </v-col>
                            <v-col v-if="!isClientCabin" cols="6">
                                <a-text-input
                                    v-model="resellerMinClientAuthoringCredit"
                                    label="Client Authoring Price"
                                    rules="required|min_value:495|max_value:20000"
                                    :hint="clientAuthoringPriceHint"
                                    persistent-hint
                                    autocomplete="off"
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <a-text-input
                                    v-model="
                                        resellerMinClientContentCampaignPrice
                                    "
                                    label="Price To Sell Amp Campaigns"
                                    rules="required|min_value:597|max_value:20000"
                                    :hint="contentCampaignPriceHint"
                                    persistent-hint
                                    autocomplete="off"
                                />
                            </v-col>
                            <v-col cols="6">
                                <a-text-input
                                    v-model="
                                        resellerMinClientRecurringContentCampaignPrice
                                    "
                                    label="Price To Sell Recurring Amp Campaigns"
                                    rules="required|min_value:497|max_value:20000"
                                    :hint="recurringContentCampaignPriceHint"
                                    persistent-hint
                                    autocomplete="off"
                                />
                            </v-col>
                            <v-col cols="6">
                                <a-text-input
                                    v-model="
                                        resellerMinClientRecurringFourContentCampaignsPrice
                                    "
                                    label="Price To Sell Recurring Four Amp Campaigns"
                                    rules="required|min_value:1297|max_value:20000"
                                    :hint="
                                        recurringFourContentCampaignsPriceHint
                                    "
                                    persistent-hint
                                    autocomplete="off"
                                />
                            </v-col>
                        </v-row>
                        <!-- Remove the next v-row within PROD-2545 -->
                        <v-row v-show="false">
                            <v-col cols="6">
                                <a-text-input
                                    v-model="resellerMinClientAuthoringSub"
                                    label="Price To Sell Old Amp Campaigns"
                                    rules="required|min_value:495|max_value:20000"
                                    :hint="ampCampaingsPriceHint"
                                    persistent-hint
                                    autocomplete="off"
                                />
                            </v-col>
                            <v-col cols="6">
                                <a-text-input
                                    v-model="resellerMinClientSnapshotPrice"
                                    label="Client Snapshot Price"
                                    rules="required|min_value:27|max_value:20000"
                                    :hint="shapshotPriceHint"
                                    persistent-hint
                                    autocomplete="off"
                                />
                            </v-col>
                        </v-row>
                        <v-row v-if="canSeeClientPremiumPrice">
                            <v-col cols="6">
                                <a-text-input
                                    v-model="
                                        resellerMinClientPremiumAmpCreditPrice
                                    "
                                    label="Client Premium Price"
                                    rules="required|min_value:299|max_value:20000"
                                    :hint="premiumPriceHint"
                                    persistent-hint
                                    autocomplete="off"
                                />
                            </v-col>
                            <v-col cols="6">
                                <a-text-input
                                    v-model="
                                        resellerMinClientRecurringPremiumAmpCreditPrice
                                    "
                                    label="Client Recurring Premium Price"
                                    rules="required|min_value:299|max_value:20000"
                                    :hint="recurringPremiumPriceHint"
                                    persistent-hint
                                    autocomplete="off"
                                />
                            </v-col>
                        </v-row>
                        <v-row v-if="canSeeClientDefiPrice">
                            <v-col cols="6">
                                <a-text-input
                                    v-model="resellerMinClientDefiCreditPrice"
                                    label="Client DeFi Price"
                                    rules="required|min_value:299|max_value:20000"
                                    :hint="defiPriceHint"
                                    persistent-hint
                                    autocomplete="off"
                                />
                            </v-col>
                            <v-col cols="6">
                                <a-text-input
                                    v-model="
                                        resellerMinClientRecurringDefiCreditPrice
                                    "
                                    label="Client Recurring DeFi Price"
                                    rules="required|min_value:299|max_value:20000"
                                    :hint="recurringDefiPriceHint"
                                    persistent-hint
                                    autocomplete="off"
                                />
                            </v-col>
                        </v-row>
                        <v-row v-if="canSeeClientBlowPrice">
                            <v-col cols="6">
                                <a-text-input
                                    v-model="resellerMinClientBlowPackPrice"
                                    label="Client B.L.O.W. Price"
                                    rules="required|min_value:299|max_value:20000"
                                    :hint="blowPriceHint"
                                    persistent-hint
                                    autocomplete="off"
                                />
                            </v-col>
                            <v-col cols="6">
                                <a-text-input
                                    v-model="
                                        resellerMinClientRecurringBlowPackPrice
                                    "
                                    label="Client Recurring B.L.O.W. Price"
                                    rules="required|min_value:299|max_value:20000"
                                    :hint="recurringBlowPriceHint"
                                    persistent-hint
                                    autocomplete="off"
                                />
                            </v-col>
                        </v-row>
                        <v-row v-if="canSeeClientMsnPrice">
                            <v-col cols="6">
                                <a-text-input
                                    v-model="resellerMinClientMsnPrice"
                                    label="Client Microsoft MSN Distribution Price"
                                    rules="required|min_value:295|max_value:20000"
                                    :hint="msnPriceHint"
                                    persistent-hint
                                    autocomplete="off"
                                />
                            </v-col>
                            <v-col cols="6">
                                <a-text-input
                                    v-model="resellerMinClientRecurringMsnPrice"
                                    label="Client Recurring Microsoft MSN Distribution Price"
                                    rules="required|min_value:295|max_value:20000"
                                    :hint="msnPriceHint"
                                    persistent-hint
                                    autocomplete="off"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions class="mx-2 pb-4 pt-0">
                        <v-btn
                            type="submit"
                            color="primary"
                            :loading="isSaving"
                            :block="$vuetify.breakpoint.smAndDown"
                        >
                            Save
                        </v-btn>
                        <v-spacer
                            v-if="subDomainUpdated"
                            :class="{ 'mt-4': $vuetify.breakpoint.smAndDown }"
                        />
                        <a
                            v-if="subDomainUpdated"
                            :href="subdomainLoginLink"
                            :class="{
                                'text-center': $vuetify.breakpoint.smAndDown
                            }"
                        >
                            Please Login to Your New Subdomain
                        </a>
                    </v-card-actions>
                </a-form>
            </v-col>
        </v-row>
    </v-card>
</template>
<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import { AForm } from '@/components/AForm';
import { ATextInput } from '@/components/AForm/Inputs/ATextInput';

import type { WhitelabelData } from '@/types/Whitelabel';

const ResellerProps = Vue.extend({
    name: 'ResellerOptions',
    props: {
        reseller: {
            type: Object as PropType<Partial<WhitelabelData>>,
            default(): Partial<WhitelabelData> {
                return {};
            }
        }
    }
});

type CurrencyOption = {
    value: string;
    label: string;
};

@Component({
    components: {
        AForm,
        ATextInput
    }
})
export default class ResellerOptions extends ResellerProps {
    business_name = '';
    product_name = '';
    subdomain = '';
    reseller_booking_url = '';
    reseller_currency = 'USD';
    currencies: Record<string, string> = {};
    resellerMinClientAuthoringCredit = 0;
    resellerMinClientAuthoringSub = 0;
    resellerMinClientSnapshotPrice = 0;
    resellerMinClientPremiumAmpCreditPrice = 0;
    resellerMinClientRecurringPremiumAmpCreditPrice = 0;
    resellerMinClientDefiCreditPrice = 0;
    resellerMinClientRecurringDefiCreditPrice = 0;
    resellerMinClientBlowPackPrice = 0;
    resellerMinClientRecurringBlowPackPrice = 0;
    resellerMinClientMsnPrice = 0;
    resellerMinClientRecurringMsnPrice = 0;

    resellerMinClientContentCampaignPrice = 0;
    resellerMinClientRecurringContentCampaignPrice = 0;
    resellerMinClientRecurringFourContentCampaignsPrice = 0;

    hasPremiumPurchase = false;

    numCryptoPremium = 0;
    numGuestPostPremium = 0;
    numMsnCredits = 0;

    isAnnouncementEngine = false;
    isClientCabin = false;

    isSaving = false;
    subDomainUpdated = false;

    get subdomainHint() {
        return `This will be the first component of your portal's URL. All whitelabel portals are hosted on ${this.siteToRedirectTo}, so your URL will be: http://my_subdomain.${this.siteToRedirectTo}`;
    }

    get unseenMachineLink() {
        if (this.subdomain) {
            return `https://${[this.subdomain, this.siteToRedirectTo].join(
                '.'
            )}/`;
        }

        return '';
    }

    get clientAuthoringPriceHint() {
        return 'This will be the price that your clients pay when they request an amp on your Amp Sales Funnel. This includes authoring and distribution.';
    }

    get ampCampaingsPriceHint() {
        return this.sentence(
            this.recommended(this.recommendedAmpValue),
            'This will be the price that your clients pay monthly when they request a recurring amp on your Amp Sales Funnel. This includes authoring and distribution.'
        );
    }

    get shapshotPriceHint() {
        return this.sentence(
            this.recommended(this.recommendedSnapshotValue),
            'This will be the price that your clients pay for a snapshot report on your Amp Sales Funnel.'
        );
    }

    get premiumPriceHint() {
        return this.sentence(
            this.recommended(this.recommendedPremiumValue),
            'This will be the price that your clients pay for premium distribution on your Premium Sales Page.'
        );
    }

    get recurringPremiumPriceHint() {
        return this.sentence(
            this.recommended(this.recommendedRecurringPremiumValue),
            'This will be the price that your clients pay for recurring premium distribution on your Premium Sales Page.'
        );
    }

    get defiPriceHint() {
        return this.sentence(
            this.recommended(this.recommendedDefiValue),
            'This will be the price that your clients pay for DeFi distribution on your DeFi Sales Page.'
        );
    }

    get recurringDefiPriceHint() {
        return this.sentence(
            this.recommended(this.recommendedRecurringDefiValue),
            'This will be the price that your clients pay for recurring DeFi distribution on your DeFI Sales Page.'
        );
    }

    get blowPriceHint() {
        return this.sentence(
            this.recommended(this.recommendedBlowValue),
            'This will be the price that your clients pay for a B.L.O.W. pack on your B.L.O.W Sales Page'
        );
    }

    get recurringBlowPriceHint() {
        return this.sentence(
            this.recommended(this.recommendedRecurringBlowValue),
            'This will be the price that your clients pay for a B.L.O.W. pack on your B.L.O.W Sales Page'
        );
    }

    get msnPriceHint() {
        return this.sentence(
            this.recommended(this.recommendedMsnValue),
            'This will be the price that your clients pay for MSN Distribution through your Sales Page'
        );
    }

    get contentCampaignPriceHint() {
        return this.sentence(
            this.recommended(this.recommendedContentCampaignPrice),
            'This will be the price for One Time Amp Campaign through your store'
        );
    }

    get recurringContentCampaignPriceHint() {
        return this.sentence(
            this.recommended(
                `${this.recommendedRecurringContentCampaignPrice}/mo`
            ),
            'This will be the price for 1 Amp Campaign per month sold through your store'
        );
    }

    get recurringFourContentCampaignsPriceHint() {
        return this.sentence(
            this.recommended(
                `${this.recommendedRecurringFourContentCampaignsPrice}/mo`
            ),
            'This will be the price for 4 Amp campaigns per month sold through your store'
        );
    }

    get siteToRedirectTo() {
        return 'clientcabin.com';
    }

    get currencySymbol() {
        if (this.reseller_currency === 'USD') {
            return '$';
        }
        if (this.reseller_currency === 'GBP') {
            return '£';
        }
        if (this.reseller_currency === 'EUR') {
            return '€';
        }

        return '';
    }

    get currencyOptions() {
        if (this.currencies) {
            return Object.entries(this.currencies).reduce(
                (acc: CurrencyOption[], [key, value]) => {
                    if (key !== 'default') {
                        acc.push({
                            value: key,
                            label: value
                        });
                    }

                    return acc;
                },
                []
            );
        }

        return [];
    }

    get canSeeClientPremiumPrice() {
        return this.hasPremiumPurchase;
    }

    // TODO (Aleksey) - double check this with Chris/Ken
    get canSeeClientDefiPrice() {
        return Number(this.numCryptoPremium) > 0;
    }

    get canSeeClientBlowPrice() {
        return Number(this.numGuestPostPremium) > 0;
    }

    get canSeeClientMsnPrice() {
        return Number(this.numMsnCredits) > 0;
    }

    get recommendedAmpValue() {
        return this.priceByCurrency(497);
    }

    get recommendedSnapshotValue() {
        return this.priceByCurrency(97);
    }

    get recommendedPremiumValue() {
        // TODO PRESC-7254 set this value
        return this.priceByCurrency(1999);
    }

    get recommendedRecurringPremiumValue() {
        // TODO ^
        return this.priceByCurrency(1499);
    }

    get recommendedDefiValue() {
        // TODO ^
        return this.priceByCurrency(1999);
    }

    get recommendedRecurringDefiValue() {
        // TODO ^
        return this.priceByCurrency(1499);
    }

    get recommendedBlowValue() {
        // TODO ^
        return this.priceByCurrency(1999);
    }

    get recommendedRecurringBlowValue() {
        // TODO ^
        return this.priceByCurrency(1499);
    }

    get recommendedMsnValue() {
        return this.priceByCurrency(645);
    }

    get recommendedContentCampaignPrice() {
        return this.priceByCurrency(1999);
    }

    get recommendedRecurringContentCampaignPrice() {
        return this.priceByCurrency(999);
    }

    get recommendedRecurringFourContentCampaignsPrice() {
        return this.priceByCurrency(1999);
    }

    get subdomainLoginLink() {
        return `${this.unseenMachineLink}/users/login`;
    }

    mounted() {
        const {
            business_name = '',
            product_name = '',
            subdomain = '',
            reseller_booking_url = '',
            reseller_currency = 'USD',
            currencies = {},
            resellerMinClientAuthoringCredit = 0,
            resellerMinClientAuthoringSub = 0,
            resellerMinClientSnapshotPrice = 0,
            resellerMinClientPremiumAmpCreditPrice = 0,
            resellerMinClientRecurringPremiumAmpCreditPrice = 0,
            resellerMinClientDefiCreditPrice = 0,
            resellerMinClientRecurringDefiCreditPrice = 0,
            resellerMinClientBlowPackPrice = 0,
            resellerMinClientRecurringBlowPackPrice = 0,
            resellerMinClientMsnPrice = 0,
            resellerMinClientRecurringMsnPrice = 0,
            resellerMinClientContentCampaignPrice = 0,
            resellerMinClientRecurringContentCampaignPrice = 0,
            resellerMinClientRecurringFourContentCampaignsPrice = 0,
            hasPremiumPurchase = false,
            numCryptoPremium = 0,
            numGuestPostPremium = 0,
            numMsnCredits = 0,
            isAnnouncementEngine = false,
            isClientCabin = false
        } = this.reseller;

        this.business_name = business_name;
        this.product_name = product_name;
        this.subdomain = subdomain;
        this.reseller_booking_url = reseller_booking_url;
        this.reseller_currency = reseller_currency ?? this.reseller_currency;
        this.currencies = currencies;
        this.resellerMinClientAuthoringCredit =
            resellerMinClientAuthoringCredit;
        this.resellerMinClientAuthoringSub = resellerMinClientAuthoringSub;
        this.resellerMinClientSnapshotPrice = resellerMinClientSnapshotPrice;
        this.resellerMinClientPremiumAmpCreditPrice =
            resellerMinClientPremiumAmpCreditPrice;
        this.resellerMinClientRecurringPremiumAmpCreditPrice =
            resellerMinClientRecurringPremiumAmpCreditPrice;
        this.resellerMinClientDefiCreditPrice =
            resellerMinClientDefiCreditPrice;
        this.resellerMinClientRecurringDefiCreditPrice =
            resellerMinClientRecurringDefiCreditPrice;
        this.resellerMinClientBlowPackPrice = resellerMinClientBlowPackPrice;
        this.resellerMinClientRecurringBlowPackPrice =
            resellerMinClientRecurringBlowPackPrice;
        this.resellerMinClientMsnPrice = resellerMinClientMsnPrice;
        this.resellerMinClientRecurringMsnPrice =
            resellerMinClientRecurringMsnPrice;

        this.resellerMinClientContentCampaignPrice =
            resellerMinClientContentCampaignPrice;
        this.resellerMinClientRecurringContentCampaignPrice =
            resellerMinClientRecurringContentCampaignPrice;
        this.resellerMinClientRecurringFourContentCampaignsPrice =
            resellerMinClientRecurringFourContentCampaignsPrice;

        this.hasPremiumPurchase = hasPremiumPurchase;
        this.numCryptoPremium = numCryptoPremium;
        this.numGuestPostPremium = numGuestPostPremium;
        this.numMsnCredits = numMsnCredits;
        this.isAnnouncementEngine = isAnnouncementEngine;
        this.isClientCabin = isClientCabin;
    }

    save() {
        this.setSaving();

        return this.setUserData()
            .then(data => {
                if (!data?.meta?.success) {
                    this.$store.dispatch(
                        'notification/error',
                        'Unable to save Whitelabel Options. Please check the form for errors.'
                    );

                    return false;
                }

                this.onSave(data);

                this.$store.dispatch(
                    'notification/success',
                    'Whitelabel Options updated'
                );
            })
            .finally(this.setSaving.bind(this, false));
    }

    setSaving(isSaving = true) {
        this.$emit('saving', isSaving);
        this.isSaving = isSaving;
    }

    setUserData() {
        return this.$http
            .post('/users/panel', this.getDataToSave())
            .then(({ data }) => data)
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }
            });
    }

    getDataToSave() {
        return (({
            business_name,
            product_name,
            subdomain,
            reseller_booking_url,
            reseller_currency,
            resellerMinClientAuthoringCredit:
                reseller_client_authoring_credit_price,
            resellerMinClientAuthoringSub:
                reseller_client_recurring_authoring_credit_price,
            resellerMinClientSnapshotPrice: reseller_client_snapshot_price,
            resellerMinClientPremiumAmpCreditPrice:
                reseller_client_premium_amp_credit_price,
            resellerMinClientRecurringPremiumAmpCreditPrice:
                reseller_client_recurring_premium_amp_credit_price,
            resellerMinClientDefiCreditPrice: reseller_client_defi_credit_price,
            resellerMinClientRecurringDefiCreditPrice:
                reseller_client_recurring_defi_credit_price,
            resellerMinClientBlowPackPrice: reseller_client_blow_pack_price,
            resellerMinClientRecurringBlowPackPrice:
                reseller_client_recurring_blow_pack_price,
            resellerMinClientMsnPrice: reseller_client_msn_price,
            resellerMinClientRecurringMsnPrice:
                reseller_client_recurring_msn_price,
            resellerMinClientContentCampaignPrice:
                reseller_client_content_campaign_price,
            resellerMinClientRecurringContentCampaignPrice:
                reseller_client_recurring_content_campaign_price,
            resellerMinClientRecurringFourContentCampaignsPrice:
                reseller_client_recurring_four_content_campaigns_price
        }) => ({
            business_name,
            product_name,
            subdomain,
            reseller_booking_url,
            reseller_currency,
            reseller_client_authoring_credit_price,
            reseller_client_recurring_authoring_credit_price,
            reseller_client_snapshot_price,
            reseller_client_premium_amp_credit_price,
            reseller_client_recurring_premium_amp_credit_price,
            reseller_client_defi_credit_price,
            reseller_client_recurring_defi_credit_price,
            reseller_client_blow_pack_price,
            reseller_client_recurring_blow_pack_price,
            reseller_client_msn_price,
            reseller_client_recurring_msn_price,
            reseller_updating_profile: 1,
            reseller_client_content_campaign_price,
            reseller_client_recurring_content_campaign_price,
            reseller_client_recurring_four_content_campaigns_price
        }))(this);
    }

    onSave(data: { data?: Record<string, string> }) {
        if (data?.data?.reseller_subdomain_updated) {
            this.subDomainUpdated = true;
        }

        this.$emit('updated', this.getDataToSave());
    }

    sentence(...parts: Array<string | boolean>) {
        return parts.filter(Boolean).join(' ');
    }

    recommended(price: string | 0) {
        return this.isAnnouncementEngine && `Recommended price: ${price}.`;
    }

    priceByCurrency(price: number) {
        if (this.reseller_currency === 'USD') {
            return this.currencySymbol + price;
        }
        if (this.reseller_currency === 'GBP') {
            return this.currencySymbol + Math.ceil(price * 0.78);
        }
        if (this.reseller_currency === 'EUR') {
            return this.currencySymbol + Math.ceil(price * 0.89);
        }

        return 0;
    }
}
</script>
