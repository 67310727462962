<template>
    <section-loading v-if="isLoading" />
    <section v-else>
        <v-card
            class="mb-2"
            :class="{ 'px-5 py-4 mb-5': $vuetify.breakpoint.mdAndUp }"
        >
            <v-row class="ma-0">
                <v-col cols="12" xl="6" class="pa-0">
                    <v-card-title>Credits</v-card-title>
                    <v-card-text
                        :class="{ 'px-0': $vuetify.breakpoint.mdAndUp }"
                    >
                        <div class="px-4 text-body-1 mb-6">
                            Here is your balance of credits for various
                            campaigns:
                        </div>
                        <v-divider />
                        <v-simple-table v-if="$vuetify.breakpoint.mdAndUp">
                            <template #default>
                                <tbody>
                                    <tr v-if="credits.presscable">
                                        <td>
                                            PressCable Credits
                                            <v-tooltip bottom>
                                                <template
                                                    #activator="{ on, attrs }"
                                                >
                                                    <v-icon
                                                        v-bind="attrs"
                                                        small
                                                        right
                                                        v-on="on"
                                                    >
                                                        circle-info
                                                    </v-icon>
                                                </template>
                                                <span v-if="!isCheckingPayment">
                                                    PressCable Credits can only
                                                    be used to publish the News
                                                    Article Module of an Amp
                                                </span>
                                                <span v-else>
                                                    We are processing your
                                                    PressCable Credits Upgrade
                                                    and this number will be
                                                    update shortly
                                                </span>
                                            </v-tooltip>
                                        </td>
                                        <td>{{ credits.presscable }}</td>
                                        <td class="text-right">
                                            <router-link
                                                v-if="!isCheckingPayment"
                                                to="/credits/convert"
                                            >
                                                Upgrade to Amp Credits
                                            </router-link>
                                            <span v-else class="font-italic">
                                                Payment Processing...
                                            </span>
                                        </td>
                                    </tr>
                                    <tr
                                        v-for="(credit, i) in creditItems"
                                        :key="i"
                                    >
                                        <td>{{ credit.label }}</td>
                                        <td>{{ credit.amount }}</td>
                                        <td class="text-right">
                                            <router-link
                                                :to="
                                                    getPurchaseLink(credit.type)
                                                "
                                            >
                                                Add more credits
                                            </router-link>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <div v-else>
                            <v-list-item
                                v-if="credits.presscable"
                                three-line
                                class="px-0"
                            >
                                <v-list-item-content>
                                    <v-list-item-title class="text-wrap">
                                        PressCable Credits
                                        <v-tooltip bottom>
                                            <template
                                                #activator="{ on, attrs }"
                                            >
                                                <v-icon
                                                    v-bind="attrs"
                                                    small
                                                    right
                                                    v-on="on"
                                                >
                                                    circle-info
                                                </v-icon>
                                            </template>
                                            <span v-if="!isCheckingPayment">
                                                PressCable Credits can only be
                                                used to publish the News Article
                                                Module of an Amp
                                            </span>
                                            <span v-else>
                                                We are processing your
                                                PressCable Credits Upgrade and
                                                this number will be update
                                                shortly
                                            </span>
                                        </v-tooltip>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <strong>
                                            {{ credits.presscable }}
                                        </strong>
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        <router-link
                                            v-if="!isCheckingPayment"
                                            to="/credits/convert"
                                        >
                                            Upgrade to Amp Credits
                                        </router-link>
                                        <span v-else class="font-italic">
                                            Payment Processing...
                                        </span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                                v-for="(credit, i) in creditItems"
                                :key="i"
                                three-line
                                class="px-0"
                            >
                                <v-list-item-content>
                                    <v-list-item-title class="text-wrap">
                                        {{ credit.label }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <strong>
                                            {{ credit.amount }}
                                        </strong>
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        <router-link
                                            :to="getPurchaseLink(credit.type)"
                                        >
                                            Add more credits
                                        </router-link>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </div>
                    </v-card-text>
                </v-col>
            </v-row>
        </v-card>
        <v-row justify="center" :dense="$vuetify.breakpoint.smAndDown">
            <v-col md="4" class="d-flex justify-center">
                <product-action :link="appointmentLink" style="max-width: 100%">
                    <template #title>Bulk Pricing</template>
                    <template #description>
                        Do you need higher volume, or would like to explore more
                        ways we can grow your business and organic traffic?
                        <br />
                        Please book a call to see what options are the best fit.
                    </template>
                    <template #button>Book Appointment</template>
                </product-action>
            </v-col>
            <v-col md="4" class="d-flex justify-center">
                <product-action :link="servicesLink" style="max-width: 100%">
                    <template #title>
                        100% Done For You Organic Traffic
                    </template>
                    <template #description>
                        Are you an established business doing over $1M per year?
                        We can grow your organic traffic for you...
                    </template>
                    <template #button>Book Appointment</template>
                </product-action>
            </v-col>
            <v-col md="4" class="d-flex justify-center">
                <product-action :link="partnersLink" style="max-width: 100%">
                    <template #title>Agency & Reseller Partnership</template>
                    <template #description>
                        Are you interested in Partnering with AmpiFire to
                        whitelabel or resell our services? Get our best bulk
                        rates and our exclusive sales tools.
                    </template>
                    <template #button>Apply as partner</template>
                </product-action>
            </v-col>
        </v-row>
    </section>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import SectionLoading from './SectionLoading.vue';
import { ProductAction } from '@/components/ProductCard';

import type { CreditsData } from '@/types/Panel';

const CreditsProps = Vue.extend({
    name: 'Credits',
    props: {
        isCheckingPayment: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        SectionLoading,
        ProductAction
    }
})
export default class Credits extends CreditsProps {
    credits: CreditsData = {
        available: false,
        authoring: 0,
        premium: 0,
        financial: 0,
        msn: 0,
        distribution: 0,
        presscable: 0,
        crypto: 0,
        blow: 0,
        isAnnouncementEngine: false
    };

    isLoading = true;

    get creditItems() {
        return [
            {
                type: 'amp',
                label: 'Amp Distribution',
                amount: this.credits.distribution
            },
            {
                type: 'dfy',
                label: 'Amp Authoring',
                amount: this.credits.authoring
            },
            {
                type: 'premium',
                label: 'Premium Big 6 Distribution',
                amount: this.credits.premium
            },
            {
                type: 'msn',
                label: 'MSN Publishing Distribution',
                amount: this.credits.msn
            },
            {
                type: 'financial',
                label: 'Financial Distribution',
                amount: this.credits.financial
            },
            {
                type: 'blow',
                label: 'Blog Link Outreach Wire Packs',
                amount: Math.floor(this.credits.blow / 4)
            },
            {
                type: 'defi',
                label: 'DeFi Distribution',
                amount: this.credits.crypto
            }
        ];
    }

    get appointmentLink() {
        return this.toLink(
            'https://try.ampifire.com/meetings/jbulzak/amp-strategy-meeting',
            {
                utm_source: 'in-app',
                utm_medium: 'credits-page',
                utm_campaign: 'bulk-pricing',
                utm_term: 'credits'
            }
        );
    }

    get partnersLink() {
        return this.toLink('https://now.ampifire.com/partners', {
            utm_source: 'in-app',
            utm_medium: 'credits-page',
            utm_campaign: 'partner-upgrade',
            utm_term: 'credits'
        });
    }

    get servicesLink() {
        return this.toLink('https://now.ampifire.com/start', {
            utm_source: 'in-app',
            utm_medium: 'credits-page',
            utm_campaign: 'managed-upgrade',
            utm_term: 'credits'
        });
    }

    mounted() {
        this.setLoading();

        return this.getUserData()
            .then(({ data }) => {
                if (data) {
                    this.credits = data.credits;
                }
            })
            .finally(this.setLoading.bind(this, false));
    }

    setLoading(isLoading = true) {
        this.$emit('loading', isLoading);
        this.isLoading = isLoading;
    }

    formatDate(value: number) {
        return value ? this.$dayjs.unix(value).format('ll') : '';
    }

    getUserData() {
        return this.$http
            .get('/users/panel')
            .then(({ data }) => data)
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }

                return {};
            });
    }

    getPurchaseLink(type: string) {
        return `/credits/purchase/${type}`;
    }

    toLink(base: string, params: Record<string, string> = {}) {
        const url = new URL(base);

        return new URL(
            `${url.origin}${url.pathname}?${new URLSearchParams([
                ...Array.from(url.searchParams.entries()),
                ...Object.entries(params)
            ])}`
        ).href;
    }
}
</script>

<style lang="scss" scoped>
.disabled {
    cursor: not-allowed;
    color: gray !important;
}
</style>
