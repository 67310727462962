<template>
    <div v-if="isClientCabin">
        <reseller-logo :reseller="reseller" />
        <reseller-profile-image :reseller="reseller" />
        <reseller-options :reseller="reseller" />
        <reseller-social-media :reseller="reseller" />
    </div>
    <v-banner v-else class="white pa-2" elevation="2" outlined rounded>
        <v-icon large color="primary" class="mr-2">circle-info</v-icon>
        Login to your domain
        <a :href="subDomainLabel">
            {{ subDomainLink }}
        </a>
        to access whitelabel options
    </v-banner>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import type { WhitelabelData } from '@/types/Whitelabel';

import ResellerLogo from './ResellerLogo.vue';
import ResellerProfileImage from './ResellerProfileImage.vue';
import ResellerOptions from './ResellerOptions.vue';
import ResellerSocialMedia from './ResellerSocialMedia.vue';

const ResellerProps = Vue.extend({
    name: 'Reseller',
    props: {
        reseller: {
            type: Object as PropType<Partial<WhitelabelData>>,
            default(): Partial<WhitelabelData> {
                return {
                    subdomain: '',
                    reseller_logo: null,
                    reseller_profile_image: null
                };
            }
        }
    }
});

@Component({
    components: {
        ResellerLogo,
        ResellerProfileImage,
        ResellerOptions,
        ResellerSocialMedia
    }
})
export default class Reseller extends ResellerProps {
    get isClientCabin() {
        return this.reseller.isClientCabin;
    }

    get subDomainLabel() {
        return `https://${[
            this.reseller.subdomain,
            'clientcabin.com/users/panel'
        ]
            .filter(Boolean)
            .join('.')}`;
    }

    get subDomainLink() {
        return [this.reseller.subdomain, 'clientcabin.com']
            .filter(Boolean)
            .join('.');
    }
}
</script>
