import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('modal',{attrs:{"id":"become-affiliate-form-modal","title":"Become an Affiliate"}},[_c(VContainer,[_c('a-form',{attrs:{"submit":_vm.becomeAnAffiliate,"disabled":_vm.isBusy}},[_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c('a-text-input',{attrs:{"label":"Email","rules":"required|email","html-hint":"","hint":"Please provide a unique email address, that has not been used previously. We will send your affiliate commissions via <strong>PayPal</strong> to this email address","placeholder":"Provide a valid PayPal email","persistent-hint":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1),_c(VRow,[_c(VSpacer),_c(VCol,{staticClass:"d-flex justify-end",attrs:{"col":"2"}},[_c(VBtn,{staticClass:"px-10 py-8 text-h6 rounded-lg",attrs:{"type":"submit","color":"primary","disabled":_vm.isBusy,"loading":_vm.isBusy}},[_vm._v(" Join ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }