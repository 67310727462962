<template>
    <modal id="become-affiliate-form-modal" title="Become an Affiliate">
        <v-container>
            <a-form :submit="becomeAnAffiliate" :disabled="isBusy">
                <v-row>
                    <v-col cols="6">
                        <a-text-input
                            v-model="email"
                            label="Email"
                            rules="required|email"
                            html-hint
                            hint="Please provide a unique email address, that has not been used previously. We will send your affiliate commissions via <strong>PayPal</strong> to this email address"
                            placeholder="Provide a valid PayPal email"
                            persistent-hint
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-spacer />
                    <v-col col="2" class="d-flex justify-end">
                        <v-btn
                            type="submit"
                            color="primary"
                            class="px-10 py-8 text-h6 rounded-lg"
                            :disabled="isBusy"
                            :loading="isBusy"
                        >
                            Join
                        </v-btn>
                    </v-col>
                </v-row>
            </a-form>
        </v-container>
    </modal>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { AForm } from '@/components/AForm';
import { ATextInput } from '@/components/AForm/Inputs/ATextInput';
import { Modal } from '@/components/Modal';

import { reduceErrors } from '@/utils/helpers';

@Component({
    name: 'BecomeAffiliateFormModal',
    components: {
        AForm,
        ATextInput,
        Modal
    }
})
export default class BecomeAffiliateFormModal extends Vue {
    email = '';

    isBusy = false;

    endpoint = '/affiliates/become';

    redirect = '/affiliates/thanks?joined=3';

    becomeAnAffiliate() {
        this.isBusy = true;
        this.$http
            .post(this.endpoint, { email: this.email })
            .then(({ data }) => {
                this.$store.dispatch(
                    'notification/success',
                    `Affiliate account(${data?.data?.affiliate?.email}) created and approved. You will be redirected shortly.`
                );
                window.location.href = this.redirect;
            })
            .catch(error => {
                const errors = new Set<string>();
                reduceErrors(error?.response?.data?.errors, errors);
                this.$store.dispatch(
                    'notification/error',
                    [...errors].join('<br/>')
                );
            })
            .finally(() => {
                this.isBusy = false;
            });
    }
}
</script>
