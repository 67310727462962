<template>
    <a-banner v-if="!isKamManagedUser">
        <!-- for reference only <span
            v-if="
                isBetween(['2024-07-03 11:00'], ['2024-07-07 11:00']) &&
                !isNewSimplerTrafficBuyer
            "
        >
            Discover Our New Traffic Playbook For 2024
            <a href="https://letsgolook.at/TrafficPlaybook2024" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                RSVP HERE
            </a>
        </span> -->

        <div v-if="isAsigoBuyerOnly" class="mb-4">
            We are excited to announce that Asigo has been upgraded to DropServe
            with new sales material, new software and new strategies to help you
            maximize your sales and profits.
            <br />
            Review our new training to discover the new platform and take part
            in our latest challenge and to grow your hands-off agency.
            <br />
            Join the sessions
            <a href="/training/dropserve/index" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                here
            </a>
        </div>

        <div
            v-if="isBetween(['2024-08-28 11:00'], ['2024-09-01 11:00'])"
            class="my-4"
        >
            NEW! Discover Chris Munch’s Brand New “Bing Backdoor” Traffic
            Method!
            <a href="https://letsgolook.at/BingBackdoor" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                RSVP To The Free Workshop Here
            </a>
        </div>

        <div
            v-if="isBetween(['2024-09-01 11:00'], ['2024-09-06 11:00'])"
            class="my-4"
        >
            Discover How To Rank #1 In Bing & Tap Into 8 Billion+ Monthly
            Visitors From Microsoft’s MSN.com, Bing, Bing News & Yahoo
            <a href="https://letsgolook.at/BingBackdoor" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                RSVP To The Free Workshop Here
            </a>
        </div>

        <div
            v-if="isBetween(['2024-09-06 11:00'], ['2024-09-07 11:00'])"
            class="my-4"
        >
            [48 HOURS] SINGLE CREDIT PACKS NOW AVAILABLE! Secure Your HUGE
            Discount On Microsoft MSN Publishing To Rank Page #1 In Bing & Tap
            Into 8+ Billion Monthly Visitors From The Microsoft Traffic
            Ecosystem
            <a href="https://letsgolook.at/MSNPublishing" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                Get MSN Publishing “Singles” Here
            </a>
        </div>

        <div
            v-if="isBetween(['2024-09-07 11:00'], ['2024-09-08 11:00'])"
            class="my-4"
        >
            [CLOSING TODAY!] LAST CHANCE To Secure Your HUGE Discount On
            Microsoft MSN Publishing To Rank Page #1 In Bing & Tap Into 8+
            Billion Monthly Visitors From The Microsoft Traffic Ecosystem
            <a href="https://letsgolook.at/MSNPublishing" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                Access MSN Publishing Here
            </a>
        </div>

        <div v-if="isDropServeBuyer">
            <span>
                Welcome to the DropServe, your DropService whitelabel platform
                where we will guide you on starting and scaling your own
                DropService business. Start here:
                <a href="https://www.dropserve.ai/thankyou" target="_blank">
                    https://www.dropserve.ai/thankyou
                </a>
            </span>
        </div>

        <div
            v-else-if="isBetween(['2024-07-29 11:00'], ['2024-07-31 11:00'])"
            class="my-4"
        >
            How To Get Approved For The AmpiFire Affiliate Program
            <a href="https://letsgolook.at/DropServiceProfits" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                RSVP Here!
            </a>
        </div>

        <div
            v-else-if="isBetween(['2024-07-31 11:00'], ['2024-08-02 11:00'])"
            class="my-4"
        >
            [CLOSING] Last Chance To Join DropServe And Grow Your Own Automated
            DropService Store With Free Fast Traffic And Our Proven $25,000,000+
            Funnel
            <a href="https://letsgolook.at/DropServeNow" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                Get Access Now!
            </a>
        </div>

        <div v-if="isReseller && !isDropServeBuyer" class="my-4">
            Your Amp Sales Funnel has been updated to a simpler, more refined
            version that will help you to improve conversions.
            <br />
            To set this up and start moving leads forward,
            <a
                href="https://docs.google.com/document/d/1wslOSG9LE4mG3TfSx0TLGokuJmsnZhY0m5heSFBhHYQ/edit"
                target="_blank"
            >
                <v-icon color="primary" x-small>arrow-right</v-icon>
                please follow the steps listed here
            </a>
        </div>

        <div v-if="showPressCableUserWelcomeMessage" class="my-4">
            You have PressCable credits remaining. Upgrade to Amp Credits
            <router-link to="/credits/convert">
                Here
                <v-icon color="primary" x-small>arrow-right</v-icon>
            </router-link>
        </div>
    </a-banner>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';

import ABanner from '@/components/ABanner';

@Component({
    components: {
        ABanner
    },
    computed: {
        ...mapGetters('user', ['isReseller']),
        ...mapState('user', [
            'isLociCycleBuyer',
            'isAsigoBuyer',
            'isDropServeBuyer',
            'isBoringMethodBuyer',
            'isNewSimplerTrafficBuyer',
            'isPressCableUser',
            'hasPressCableCredits',
            'isKamManagedUser'
        ])
    }
})
export default class WelcomeBanner extends Vue {
    isReseller!: boolean;
    isLociCycleBuyer!: boolean;
    isAsigoBuyer!: boolean;
    isDropServeBuyer!: boolean;
    isBoringMethodBuyer!: boolean;
    isNewSimplerTrafficBuyer!: boolean;
    isPressCableUser!: boolean;
    hasPressCableCredits!: boolean;
    isKamManagedUser!: boolean;

    get timestamp() {
        return Math.round(new Date().getTime() / 1000);
    }

    get showPressCableUserWelcomeMessage() {
        return this.isPressCableUser && this.hasPressCableCredits;
    }

    get isValidAsigoBuyer() {
        return this.isAsigoBuyer || this.isDropServeBuyer;
    }

    get isAsigoBuyerOnly() {
        return this.isAsigoBuyer && !this.isDropServeBuyer;
    }

    isBetween(
        [fromDate, fromZone = 'America/New_York']: string[],
        [toDate, toZone = 'America/New_York']: string[]
    ) {
        const now = this.$date();

        return (
            now.isAfter(this.$date(fromDate).tz(fromZone, true)) &&
            now.isBefore(this.$date(toDate).tz(toZone, true))
        );
    }
}
</script>
