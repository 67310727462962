import type { QuickLinksData, QuickLinkData } from '@/types/User';
import LinksPool from '../LinksPool';

export default (data: QuickLinksData) => {
    const links: QuickLinkData[] = [
        LinksPool.asigo_sales_funnel_settings,
        LinksPool.custom_pricing_link
    ];

    if (
        data.resellerMinClientPremiumAmpCreditPrice &&
        data.resellerMinClientRecurringPremiumAmpCreditPrice &&
        data.creditsNumPremium
    ) {
        links.push(LinksPool.premium_credits_sales_page);
    }

    if (
        data.resellerMinClientDefiCreditPrice &&
        data.resellerMinClientRecurringDefiCreditPrice &&
        data.creditsNumCryptoPremium
    ) {
        links.push(LinksPool.defi_credits_sales_page);
    }

    if (
        data.resellerMinClientBlowPackPrice &&
        data.resellerMinClientRecurringBlowPackPrice &&
        data.creditsNumGuestPostPremium
    ) {
        links.push(LinksPool.blow_packs_sales_page);
    }

    if (
        data.resellerMinClientMsnPrice &&
        data.resellerMinClientRecurringMsnPrice &&
        data.creditsNumMsn
    ) {
        links.push(LinksPool.msn_sales_page);
    }

    // hide until further notice
    // links.push(LinksPool.become_an_affiliate, LinksPool.affiliate_login);

    return links;
};
