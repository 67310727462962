import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VNavigationDrawer,{staticClass:"pl-0",attrs:{"permanent":"","floating":"","width":"100%","mini-variant":_vm.$vuetify.breakpoint.smAndDown}},[(_vm.shouldRenderHeader)?_c(VList,{staticClass:"main-background pt-0"},[_c(VListItem,{staticClass:"px-0"},[(_vm.loading)?_c(VListItemContent,[_c(VListItemTitle,{staticClass:"mb-n2 mt-n3"},[_c(VSkeletonLoader,{staticClass:"mt-0",attrs:{"loading":"","type":"heading"}}),_c(VSkeletonLoader,{staticClass:"mt-1 mb-1",attrs:{"loading":"","type":"text","width":"50%"}})],1)],1):_c(VListItemContent,{staticClass:"pt-0"},[_c(VListItemTitle,{staticClass:"text-h6 text-wrap"},[_vm._v(" "+_vm._s(_vm.user.firstname)+" "+_vm._s(_vm.user.lastname)+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.user.email)+" ")])],1)],1),_c(VDivider)],1):_vm._e(),(_vm.loading)?_c(VList,{staticClass:"main-background px-0 pt-3",class:{ 'mt-n2 py-0': _vm.$vuetify.breakpoint.smAndDown },attrs:{"nav":"","dense":_vm.$vuetify.breakpoint.smAndDown}},_vm._l((4),function(i){return _c(VListItem,{key:i,staticClass:"justify-center"},[_c(VListItemIcon,{staticClass:"justify-center",class:{
                    'pa-0 ma-0 pl-1 pt-2 mr-4': _vm.$vuetify.breakpoint.mdAndUp
                }},[_c(VSkeletonLoader,{staticStyle:{"border-radius":"50%","width":"22px","height":"22px"},attrs:{"loading":"","type":"button"}})],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"mt-n1"},[_c(VSkeletonLoader,{attrs:{"loading":"","type":"text"}})],1)],1)],1)}),1):_c(VList,{staticClass:"main-background px-0",class:{ 'py-0': _vm.$vuetify.breakpoint.smAndDown },attrs:{"nav":"","dense":_vm.$vuetify.breakpoint.smAndDown}},[_c(VListItemGroup,_vm._l((_vm.available),function(section,i){return _c(VListItem,{key:i,staticClass:"justify-center",attrs:{"href":section.link,"to":section.link ? null : _vm.absolute(section)}},[_c(VListItemIcon,{staticClass:"justify-center align-center",class:{ 'pl-2 mr-4': _vm.$vuetify.breakpoint.mdAndUp }},[_c(VIcon,{staticStyle:{"width":"20px"},attrs:{"small":""}},[_vm._v(" "+_vm._s(section.icon)+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"text-h7 text-wrap"},[_vm._v(" "+_vm._s(section.label)+" ")])],1)],1)}),1)],1),(!_vm.loading)?_c('div',{staticClass:"mt-8"},[_vm._t("default")],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }