<template>
    <v-navigation-drawer
        permanent
        floating
        width="100%"
        class="pl-0"
        :mini-variant="$vuetify.breakpoint.smAndDown"
    >
        <v-list v-if="shouldRenderHeader" class="main-background pt-0">
            <v-list-item class="px-0">
                <v-list-item-content v-if="loading">
                    <v-list-item-title class="mb-n2 mt-n3">
                        <v-skeleton-loader
                            loading
                            type="heading"
                            class="mt-0"
                        />
                        <v-skeleton-loader
                            loading
                            type="text"
                            class="mt-1 mb-1"
                            width="50%"
                        />
                    </v-list-item-title>
                </v-list-item-content>
                <v-list-item-content v-else class="pt-0">
                    <v-list-item-title class="text-h6 text-wrap">
                        {{ user.firstname }} {{ user.lastname }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        {{ user.email }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-divider />
        </v-list>
        <v-list
            v-if="loading"
            nav
            :dense="$vuetify.breakpoint.smAndDown"
            class="main-background px-0 pt-3"
            :class="{ 'mt-n2 py-0': $vuetify.breakpoint.smAndDown }"
        >
            <v-list-item v-for="i in 4" :key="i" class="justify-center">
                <v-list-item-icon
                    :class="{
                        'pa-0 ma-0 pl-1 pt-2 mr-4': $vuetify.breakpoint.mdAndUp
                    }"
                    class="justify-center"
                >
                    <v-skeleton-loader
                        loading
                        type="button"
                        style="border-radius: 50%; width: 22px; height: 22px"
                    />
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title class="mt-n1">
                        <v-skeleton-loader loading type="text" />
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-list
            v-else
            nav
            class="main-background px-0"
            :class="{ 'py-0': $vuetify.breakpoint.smAndDown }"
            :dense="$vuetify.breakpoint.smAndDown"
        >
            <v-list-item-group>
                <v-list-item
                    v-for="(section, i) in available"
                    :key="i"
                    :href="section.link"
                    :to="section.link ? null : absolute(section)"
                    class="justify-center"
                >
                    <v-list-item-icon
                        :class="{ 'pl-2 mr-4': $vuetify.breakpoint.mdAndUp }"
                        class="justify-center align-center"
                    >
                        <v-icon small style="width: 20px">
                            {{ section.icon }}
                        </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title class="text-h7 text-wrap">
                            {{ section.label }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>
        <div v-if="!loading" class="mt-8">
            <slot></slot>
        </div>
    </v-navigation-drawer>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import Profile from '@/pages/Profile/Profile.vue';

import type { Section } from '@/types/Section';

import type { ProfileUser } from '@/types/User';

const SectionsDrawerProps = Vue.extend({
    name: 'SectionsDrawer',
    props: {
        user: {
            type: Object as PropType<ProfileUser>,
            required: true
        },
        loading: {
            type: Boolean,
            default() {
                return true;
            }
        },
        sections: {
            type: Array as PropType<Section[]>,
            required: true,
            default() {
                return [];
            }
        },
        context: {
            type: Object as PropType<InstanceType<typeof Profile>>,
            required: true
        }
    }
});

@Component
export default class SectionsDrawer extends SectionsDrawerProps {
    withHeader = true;

    get shouldRenderHeader() {
        return this.withHeader && this.$vuetify.breakpoint.mdAndUp;
    }

    get available(): Section[] {
        return this.sections.filter(section => section.enabled(this.context));
    }

    created() {
        if (!this.$route.params.section && this.available.length) {
            this.$router.replace({
                path: [this.$route.path, this.available[0].id].join('/')
            });
        }
    }

    absolute(section: Section) {
        return `/profile/${section.id}`;
    }
}
</script>
